import React from 'react';
import { observer } from 'mobx-react-lite';
import { FormattedMessage, useIntl } from 'react-intl';
import { MenuItem } from '@material-ui/core';

import {
  AnvIcon,
  AnvMenu,
  AnvPaginationMui,
  AnvTableWrapper,
  useCommonTable,
  useMenu,
  useModal,
  AnvBreadcrumbs,
  AnvTable,
  AnvTitle,
  AnvUtils,
} from '@platform/front-lib';

import { useStores } from '../../stores/root-store';

import { TIcon } from '../../components/shared/icons';

import DetailInformationModal from './detail-information';
import { invitationColumns } from './columns';

import { INVITATION_STATUSES, PAGINATION_LIMIT } from '../../constants';

import { drawerOperatorMap } from '../../constants/drawer-map';

interface IProps {}
const defaultFilter = {
  status: INVITATION_STATUSES.PENDING,
  toolCode: '',
  fullName: '',
  email: '',
};
const Invitations: React.FC<IProps> = observer(() => {
  const { locale, formatMessage } = useIntl();
  const { closeMenu, handleClose: handleCloseMenu } = useMenu();
  const { handleOpenModal, isOpen, handleCloseModal } = useModal();

  const {
    metaStores: {
      toolsStore: { data: tools },
      invitationStatusesStore: {
        getEntities: getInvitationStatuses,
        dataMap: invitationStatusesMap,
        data: invitationStatuses,
        isLoaded: isLoadedInvitationStatuses,
      },
    },
    invitationStore: {
      getEntities: getAllInvitations,
      // delete: deleteOneUser,
      data: invitations,
      dataMap: invitationsMap,
      isLoaded,
      pagination,
      setPage,
      setFilter,
      setSort,
      filter,
    },
  } = useStores();

  React.useEffect(() => {
    if (!isLoadedInvitationStatuses) {
      getInvitationStatuses();
    }
  }, [isLoadedInvitationStatuses, getInvitationStatuses]);

  const [invitationId, setInvitationId] = React.useState<null | string>(null);

  const handleViewDetails = (data: Record<string, any>) => {
    setInvitationId(data?._id);
    handleCloseMenu();
    handleOpenModal();
  };
  // const confirmDelete = () => {
  //   if (!!userId) {
  //     deleteOneUser(
  //       { _id: userId },
  //       {
  //         successFunc: () => {
  //           handleToggleModal();
  //           getAllUsers({
  //             page: 1,
  //             sort: querySort,
  //             filter: memoQueryFilter,
  //             limit: PAGINATION_LIMIT,
  //           });
  //         },
  //       },
  //     );
  //   }
  // };
  // const cancelDelete = () => {
  //   handleToggleModal();
  //   setUserId(null);
  // };
  const {
    querySort,
    // queryFilter,
    queryPage,
    filterState,
    memoQueryFilter,
    filterMemo,
    handleChangeFilterDate,
    handleChangeFilter,
    handleChangePhone,
    handleChangePage,
  } = useCommonTable({
    defaultFilterState: defaultFilter,
    pagination,
    setPage,
    setFilter,
    setSort,

    storeSorting: '',
    storeFilter: filter,
  });

  React.useEffect(() => {
    getAllInvitations({
      page: +queryPage,
      sort: querySort,
      filter: memoQueryFilter,
      limit: PAGINATION_LIMIT,
    });
  }, [getAllInvitations, memoQueryFilter, queryPage, querySort]);

  const toolsOptions = React.useMemo(() => {
    return AnvUtils.prepareOptions(tools, { locale });
  }, [tools, locale]);

  const invitationStatusesOptions = React.useMemo(() => {
    return AnvUtils.prepareOptions(invitationStatuses, { locale });
  }, [invitationStatuses, locale]);

  const columns = React.useMemo(() => {
    return invitationColumns(locale, formatMessage, invitationStatusesMap, {
      toolsOptions,
      invitationStatusesOptions,
    });
  }, [
    formatMessage,
    toolsOptions,
    invitationStatusesOptions,
    invitationStatusesMap,
    locale,
  ]);

  const data = React.useMemo(() => {
    return invitations || [];
  }, [invitations]);

  return (
    <>
      <AnvBreadcrumbs
        isOperator
        items={[
          {
            component: (
              <>
                <AnvIcon
                  size={24}
                  icon={drawerOperatorMap.invitations.icon as TIcon}
                  offset={1}
                />
                <FormattedMessage
                  tagName={'span'}
                  id={drawerOperatorMap.invitations.nameI18n}
                  defaultMessage={drawerOperatorMap.invitations.name}
                />
              </>
            ),
          },
        ]}
      />

      <AnvTableWrapper>
        <AnvTitle
          tableTitle={
            <FormattedMessage
              id="SUPPORT.INVITATIONS.TITLE"
              defaultMessage="Invitations"
            />
          }
        />

        <AnvTable
          data={data}
          columns={columns}
          querySort={querySort}
          filterState={filterState}
          handleChangePhone={handleChangePhone}
          handleChangeFilter={handleChangeFilter}
          handleChangeFilterDate={handleChangeFilterDate}
          TableActionComponent={({ data }) => (
            <AnvMenu closeMenu={closeMenu}>
              <MenuItem
                onClick={() => {
                  handleViewDetails(data);
                }}
                title={formatMessage({
                  id: 'SUPPORT.INVITATIONS.LIST.ACTION.VIEW_DETAILS',
                  defaultMessage: 'View details',
                })}
              >
                <AnvIcon icon={'eyeOutline'} color={'inherit'} />
                <span>
                  <FormattedMessage
                    id="SUPPORT.INVITATIONS.LIST.ACTION.VIEW_DETAILS"
                    defaultMessage="View details"
                  />
                </span>
              </MenuItem>
            </AnvMenu>
          )}
          setFilter={setFilter}
          filterMemo={filterMemo}
          isShowTable={true}
          isLoadedList={isLoaded}
          noDataMessage={formatMessage({
            id: 'SUPPORT.INVITATIONS.LIST.NO_DATA_MESSAGE',
            defaultMessage: 'There is no data in this list',
          })}
        />

        <AnvPaginationMui
          pagination={pagination}
          totalPages={pagination?.totalPages}
          handleChangePage={handleChangePage}
          page={+queryPage}
        />
      </AnvTableWrapper>
      {isOpen && !!invitationId && (
        <DetailInformationModal
          isOpen={isOpen}
          handleCloseModal={handleCloseModal}
          entity={invitationsMap[invitationId]}
        />
      )}
    </>
  );
});

export default Invitations;
